import React from "react";
import Layout from "./src/components/Layout";
import SimpleReactLightbox from "simple-react-lightbox";
import "bootstrap/dist/css/bootstrap.min.css";

export function wrapPageElement({ element, props }){
    return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
    return <SimpleReactLightbox>{element}</SimpleReactLightbox>
}
