import React from "react";
import styled from "styled-components";

const FooterStyles = styled.footer`
  width: 100%;
  text-align: left;
  padding: 2rem 3vw;
  box-sizing: border-box;

  p {
    color: #000000;
    margin: 0;
    font-size: calc((1 - 1) * 1.2vw + 1rem);
    font-weight: 300;
  }
`;

function Footer(){
    return (
        <FooterStyles>
            {/* <ul>
                <li><a href="https://www.behance.net/alexanderpeterson/" target="_blank"><FaBehance aria-label="Behance"/></a></li>
                <li><a href="https://www.instagram.com/alexander___peterson/" target="_blank"><FaInstagram aria-label="Instagram"/></a></li>
            </ul> */}
            <p>&copy; {new Date().getFullYear()} Alexander Peterson Photography</p>
        </FooterStyles>
    )
}

export default Footer;
