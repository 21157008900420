import React, {useState, useEffect} from "react";
import {Link} from "gatsby";
import styled from "styled-components";
import logo from "../assets/images/logo-old.png";
import { FaInstagram, FaBehance } from "react-icons/fa";

const NavStyles = styled.nav`
  .menu-container {
    width: 100%;
    padding: 2vw 3vw 2vw 3vw;
    box-sizing: border-box;
  }
  
  .menu-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu-logo {
      width: auto;
      img {
        width: auto;
        max-width: 100%;
        max-height: 65px;
        display: block;
      }
    }

    .menu-nav {
      display: flex;
      align-items: center;

      li:not(:first-child) {
        margin-left: 1.7vw;
      }

      a {
        color: #000000;
        font-size: calc((1.1 - 1) * 1.2vw + 1rem);
        font-weight: 300;
        text-decoration: none;
        cursor: pointer;
      }

      a:hover {
        color: rgba(0,0,0,.7);
      }

      .menu-divider {
        color: #c2c2c2;
      }
    }

    .menu-social-links {
      display: flex;
      align-items: center;
      li, a {
        line-height: 0;
      }
      a {
        font-size: 19px;
        color: #c2c2c2;
      }
      li:not(:first-child) {
        margin-left: 1vw;
      }
    }
  }

  .menu-mobile {
    display: none;
    z-index: 2;
  }

  .menu-mobile-slide {
    position: fixed;
    background-color: #ffffff;
    width: 100%;
    z-index: 1;
  }

  .menu-mobile-slide-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .slide-menu-list {
      display: flex;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      li {
        margin: 2.1vw 0;
        a {
          color: #000000;
          font-size: 1.8rem;
          font-weight: 300;
          text-decoration: none;
        }
      }
    }
    .slide-menu-socials {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 1;
      margin-bottom: 22vw;
      li, a {
        line-height: 0;
      }
      a {
        font-size: 1.4rem;
        color: #c2c2c2;
      }
      li:not(:first-child) {
        margin-left: 3vw;
      }
    }
  }
  
  .menu-mobile-icon.open {
    .menu-line--1 {
      transform-origin: left top;
      transform: rotate(45deg);
    }

    .menu-line--2 {
      opacity: 0;
    }

    .menu-line--3 {
      transform-origin: left bottom;
      transform: rotate(-45deg);
    }
  }

  .menu-mobile-slide {
    transition: all 400ms cubic-bezier(.4,0,.2,1);
    transform: translateX(100%);
  }

  .menu-mobile-slide.open {
    transform: translateX(0);
  }

  @media screen and (max-width: 800px){
    .menu-container {
      padding: 4vw;
    }

    .menu-desktop {
      display: none;
    }

    .menu-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
        .menu-logo {
          width: auto;
          img {
            width: auto;
            max-width: 100%;
            max-height: 55px;
            display: block;
          }
        }
      .menu-mobile-icon {
        cursor: pointer;
        width: 35px;
        .menu-line {
          height: 2px;
          width: 100%;
          background-color: #282828;
          margin-bottom: 8px;
          transition: all .2s ease;
          &.menu-line--2 {
            width: 22px;
          }
          &.menu-line--3 {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px){
    .menu-mobile {
      .menu-logo {
          img {
            max-height: 55px;
          }
      }
      .menu-mobile-icon {
        .menu-line {
          margin-bottom: 7px;
        }
      }
    }
  }

  @media screen and (max-width: 576px){
    .menu-mobile {
      .menu-logo {
        img {
          max-height: 38px;
        }
      }
      .menu-mobile-icon {
        width: 28px;
        .menu-line {
          margin-bottom: 6px;
        }
      }
    }
  }
`;

function Nav() {
    const [menuActive, setMenuActive] = useState(false);

    useEffect(() => {
      if (typeof window === 'undefined') return;
      const body = document.body;
  
      if (menuActive) {
        body.style.overflowY = 'hidden';
      } else {
        body.style.overflowY = 'visible';
      }
    }, [menuActive]);
    
    return (
      <>
        <NavStyles>
          <div className="menu-container">
            <div className="menu-desktop">
              <div className="menu-logo">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <ul className="menu-nav">
                  <li>
                      <Link to="/" activeClassName="link-active">Home</Link>
                  </li>
                  <li>
                      <Link to="/works" activeClassName="link-active">Works</Link>
                  </li>
                  {/* <li>
                      <Link to="/prices" activeClassName="link-active">Prices</Link>
                  </li> */}
                  <li>
                      <Link to="/about" activeClassName="link-active">About</Link>
                  </li>
                  <li>
                      <Link to="/contact" activeClassName="link-active">Contact</Link>
                  </li>
                  <li className="menu-divider">
                    |
                  </li>
                  <li>
                    <ul className="menu-social-links">
                      <li><a href="https://www.instagram.com/alexander___peterson/" target="_blank"><FaInstagram aria-label="Instagram"/></a></li>
                      <li><a href="https://www.behance.net/alexanderpeterson/" target="_blank"><FaBehance aria-label="Behance"/></a></li>
                    </ul>
                  </li>
              </ul>
            </div>
            <div className="menu-mobile">
              <div className="menu-logo">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <div className={menuActive ? "menu-mobile-icon open" : "menu-mobile-icon"} onClick={() => setMenuActive(!menuActive)}>
                <div className="menu-line menu-line--1"></div>
                <div className="menu-line menu-line--2"></div>
                <div className="menu-line menu-line--3"></div>
              </div>
            </div>
          </div>
          <div className={menuActive ? "menu-mobile-slide open" : "menu-mobile-slide"}>
            <div className="menu-mobile-slide-container">
              <ul className="slide-menu-list">
                <li>
                  <Link to="/" activeClassName="link-active" onClick={() => setMenuActive(!menuActive)}>Home</Link>
                </li>
                <li>
                  <Link to="/works" activeClassName="link-active" onClick={() => setMenuActive(!menuActive)}>Works</Link>
                </li>
                {/* <li>
                  <Link to="/prices" activeClassName="link-active" onClick={() => setMenuActive(!menuActive)}>Prices</Link>
                </li> */}
                <li>
                  <Link to="/about" activeClassName="link-active" onClick={() => setMenuActive(!menuActive)}>About</Link>
                </li>
                  <li>
                    <Link to="/contact" activeClassName="link-active" onClick={() => setMenuActive(!menuActive)}>Contact</Link>
                </li>
              </ul>
              <ul className="slide-menu-socials">
                <li><a href="https://www.instagram.com/alexander___peterson/" target="_blank"><FaInstagram aria-label="Instagram"/></a></li>
                <li><a href="https://www.behance.net/alexanderpeterson/" target="_blank"><FaBehance aria-label="Behance"/></a></li>
              </ul>
            </div>
          </div>
        </NavStyles>
      </>
    )
}

export default Nav;
