import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-size: 100%;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  ul, li {
    padding: 0;
    margin: 0;
  }

  .flex-this-box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
      flex: 1 0 auto;
    }
  }
`;

export default GlobalStyles;
