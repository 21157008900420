import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";



export default function Layout({ children }){
    return (
        <div>
            <GlobalStyles/>
            <Typography />
            <div className="flex-this-box">
                <Nav />
                <main>{children}</main>
                <Footer />
            </div>
        </div>
    )
}
