import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  html {
    font-family: Lato, Helvetica, sans-serif;
    color: #000;
  }
  
  p, li {
    letter-spacing: 0.5px;
    list-style: none;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  
  a {
    /* Chrome renders this weird with this font, so we switch it off */
    text-decoration-skip-ink: none;
  }

`;

export default Typography;
